import React, { useState, useEffect } from "react";
import { Table, Input, Spin } from "antd";
import { GoUnverified } from "react-icons/go";
import { FiSearch } from "react-icons/fi";
import {
  RiEye2Line,
  RiDeleteBin2Fill,
  RiEdit2Fill,
  RiSendPlaneFill,
} from "react-icons/ri";
import moment from "moment";
import CustomDeleteModal from "../custom_components/customDeleteModal";
import {
  verifyCarProduct,
  fetchBicycles,
  deleteBicycleProduct,
} from "../stores/api_calls/carlists";
import CustomConfirmModal from "../custom_components/customeConfirmModal";
import { IconButton } from "../custom_components/customButton";
import { createSearchParams, useNavigate } from "react-router-dom";
import Kjur from "../stores/utils/jwt";
import { error, success } from "../contants/snackbars";
import CustomSMSModal from "../custom_components/customSMSModal";
import { sendSMS } from "../stores/api_calls/upload";
import BicycleView from "../custom_components/BicycleView";
import "../lists_cars/carlist.scss";

const DraftBicycleList = () => {
  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [keywordSearch, setKeywordSearch] = useState("");
  const [showModal, setShowModal] = useState({
    showDelete: false,
    showVerify: false,
    showPreview: false,
    showSMS: false,
    data: {},
  });
  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: 10,
    total: 30,
    defaultPageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "30"],
  });

  const columns = [
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      render: (text, record) =>
        moment.utc(record.date_created).local().format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Make & Model",
      dataIndex: "product_name",
      // sorter: true,
      render: (text, record) => `${record.product_name}`,
    },
    {
      title: "Condition",
      dataIndex: "condition",
      render: (text, record) => `${record.condition ?? "-"}`,
    },
    {
      title: "Brand",
      dataIndex: "brand",
      render: (text, record) =>
        `${record.brand ? record.brand : "-"}`,
      // width: '20%',
    },
    {
      title: "Direct Seller",
      dataIndex: "directSeller",
      render: (text, record) => 
      `${record.advertisement_contact_details?.direct_seller == true ? record.advertisement_contact_details?.name : ''}`,
    },
    {
      title: "Advertiser / Dealer",
      dataIndex: "advertiser",
      render: (text, record) => 
      `${record.advertisement_contact_details?.direct_seller == false ? record.advertisement_contact_details?.name : ''}`,
    },

    {
      title: "Product Price",
      dataIndex: "price",
      render: (text, record) => `S$ ${record.product_price.toLocaleString()}`,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (text, record) => (
        <div className="actions-icons-container">
          <IconButton
            className="icon-button view"
            tootTipTitle="View Bike"
            Icon={<RiEye2Line />}
            onClick={() => onPreviewProduct(record)}
          />
          <IconButton
            className="icon-button edit"
            tootTipTitle="Edit Bike"
            Icon={<RiEdit2Fill />}
            onClick={() => onEdit(record)}
          />
          <IconButton
            className="icon-button delete"
            tootTipTitle="Delete Bike"
            Icon={<RiDeleteBin2Fill />}
            onClick={() => onDelete(record)}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    refetchLists();
  }, []);

  useEffect(() => {
    if (tablePagination.current || tablePagination.pageSize) {
      refetchLists();
    }
  }, [tablePagination.current, tablePagination.pageSize]);

  const refetchLists = () => {
    const params = {
      page: tablePagination.current,
      limit: tablePagination.pageSize,
      keyword: keywordSearch,
      product_status: "draft"
    };

    fetchData(params);
  };

  const fetchData = (params) => {
    setIsLoading(true);

    const unverifiedCars = fetchBicycles(params)

    unverifiedCars
      .then((res) => {
        if (res.data.success) {
          setTableData(res.data.data);
          setTablePagination({
            ...tablePagination,
            total: res.data.meta.pagination.total,
          });
          setIsLoading(false);
        }
      })
      .catch((e) => {
        error(
          `Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`
        );
      });
  };

  const onEnter = (e) => {
    if (e.key === "Enter") {
      const pagination = {
        page: 1,
        limit: 10,
        keyword: keywordSearch,
        product_status: "draft"
      };
      fetchData(pagination);
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTablePagination(pagination);
  };

  const onEdit = (record) => {
    navigate(
      {
        pathname: "/bicycles/edit",
        search: `?${createSearchParams({ id: record._id })}`,
      },
      { replace: true }
    );
  };

  const onPreviewProduct = (record) => {
    setShowModal({ ...showModal, showPreview: true, data: record });
  };

  const onDelete = (record) => {
    setShowModal({ ...showModal, showDelete: true, data: record });
  };

  const onDeleteSubmit = () => {
    const filteredData = tableData.filter(
      (el) => el._id !== showModal.data._id
    );

    const onDelete = deleteBicycleProduct(showModal.data._id);
    onDelete
      .then((res) => {
        if (res.data.success) {
          setTableData(filteredData);
          setShowModal({ ...showModal, showDelete: false, data: {} });
          success("Bike deleted successfully.");
        }
      })
      .catch((e) => {
        error(`${e.response.data.server_response}`);
      });
  };

  return (
    <div className="table-container">
      <div className="table-title-icon unverified">
        <GoUnverified />
      </div>
      <p className="table-title-text unverified">Draft Bicycle Lists</p>

      <div className="search-container">
        <Input
          size="large"
          style={{ width: 300 }}
          placeholder="Search Record"
          prefix={<FiSearch />}
          onChange={(e) => setKeywordSearch(e.target.value)}
          onKeyPress={onEnter}
        />
      </div>

      <div className="table-div">
        <Table
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={tableData}
          pagination={tablePagination}
          loading={{ indicator: <Spin size="large" />, spinning: isLoading }}
          onChange={handleTableChange}
          bordered
          sticky
        />
      </div>

      <BicycleView
        title="Product View"
        visible={showModal.showPreview}
        data={showModal.data}
        onCancel={() =>
          setShowModal({ ...showModal, showPreview: false, data: {} })
        }
      />
      <CustomDeleteModal
        title="Delete Bike Ad"
        visible={showModal.showDelete}
        onSubmit={onDeleteSubmit}
        onCancel={() =>
          setShowModal({ ...showModal, showDelete: false, data: {} })
        }
        data={{ itemName: `${showModal.data.product_name}`, type: "car ad" }}
      />
    </div>
  );
};

export default DraftBicycleList;
