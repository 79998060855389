class Validator {
    constructor(value = '') {
        this.value = value;
        this.error = '';
    }

    Required() {
        if (this.value === '' || this.value === null || this.value === undefined) {
            if (this.error === '') {
                this.error = 'This field is required.';
            }
        }
        return this;
    }

    Max(length) {
        if (this.value.length > length) {
            if (this.error === '') {
                this.error = `Value exceeds maximum length of ${length}.`;
            }
        }
        return this;

    }

    Min(length) {
        if (this.value.length < length) {
            if (this.error === '') {
                this.error = `Value is less than minimum length of ${length}.`;
            }
        }
        return this;
    }

    Email() {
        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!emailRegex.test(this.value)) {
            if (this.error === '') {
                this.error = `Invalid email address.`;
            }
        }
        return this;
    }

    SingaporeMobileNumber() {
        const regex = /^[89]\d{7}$/;
        if (!regex.test(this.value)) {
            if (this.error === '') {
                this.error = `Invalid phone number (Only Singapore mobile number).`;
            }
        }
        return this;
    }

    AllSingaporePhoneNumber() {
        const regex = /^\+65\d{6,8}$/;
        console.log("Value: ", this.value)

        if (!regex.test('+' + this.value)) {
            if (this.error === '') {
                this.error = `Invalid phone number (Only Singapore).`;
            }
        }
        return this;
    }

    IsNumber() {
        if (isNaN(this.value)) {
            if (this.error === '') {
                this.error = 'Value is not a number.';
            }
        }
        return this;
    }

    MinimumNumber(number) {
        if (this.value < number) {
            if (this.error === '') {
                this.error = `Value is less than ${number}.`;
            }
        }
        return this;
    }

    MaximumNumber(number) {
        if (this.value > number) {
            if (this.error === '') {
                this.error = `Value is greater than ${number}.`;
            }
        }
        return this;
    }

    Digit(length) {
        const regex = new RegExp("^[0-9]{" + length + "}$");
        if (!regex.test(this.value)) {
            if (this.error === '') {
                this.error = `Value must be ${length} digits.`;
            }
        }
        return this;
    }

    Password() {
        const password = this.value;
        const hasLowercase = /[a-z]/.test(password);
        const hasUppercase = /[A-Z]/.test(password);
        const isValidLength = password.length >= 8;

        if (!isValidLength || !hasLowercase || !hasUppercase) {
            if (this.error === '') {
                this.error = `Invalid password! (A lowercase letter, A uppercase letter, Minimum 8 characters).`;
            }
        }

        return this;
    }

    ImageFileType(image_types) {
        if (typeof this.value !== "object") {
            if (this.error === '') {
                this.error = `Invalid Input!`;
                return this;
            }
        }

        if (!image_types.includes(this.value.type)) {
            if (this.error === '') {
                const types = image_types.map(type => type.toLowerCase());
                this.error = `Please upload a valid file type (${types.join(', ')}).`;
            }
        }
        return this;
    }

    ImageFileExtension(extensions) {
        if (typeof this.value !== "object") {
            if (this.error === '') {
                this.error = `Invalid Input!`;
                return this;
            }
        }

        const fileExtension = this.value.name.split('.').pop().toLowerCase();
        if (!extensions.includes(fileExtension)) {
            if (this.error === '') {
                const types = extensions.map(type => `.${type.toLowerCase()}`);
                this.error = `Please upload a valid file extension (${types.join(', ')}).`;
            }
        }
        return this;
    }

    FileSize(limit) {
        if (typeof this.value !== "object") {
            if (this.error === '') {
                this.error = `Invalid Input!`;
                return this;
            }
        }

        const maxFileSize = limit * 2024 * 2024;
        if (this.value.size > maxFileSize) {
            if (this.error === '') {
                this.error = `File size should not exceed ${limit} MB.`;
            }
        }
        return this;
    }


}

export default Validator;