import { Tabs } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { IoMdAddCircleOutline } from "react-icons/io";
import "../cars/carform.scss";
import {
    fetchBicycleDetails,
    fetchPackages,
} from "../../stores/api_calls/carlists";
import UploadBicycleImages from "./UploadBicycleImages";
import { useLocation } from "react-router-dom";
import { fetchUsers } from "../../stores/api_calls/users";
import BicycleDetails from "./BicycleDetails";
import PreviewBicycle from "./PreviewBicycle";

const { TabPane } = Tabs;

const BicycleFormIndex = (props) => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [isEdit, setIsEdit] = useState(false);
    // const [roles,permissions] = useContext(AccessContext)
    const [dealers, setDealers] = useState({ loading: false, data: [] });
    const [packages, setPackages] = useState([]);
    const [noOfImages, setNoOfImages] = useState(9);
    const [form, setForm] = useState({
        dealerId: ``,
        // registrationDate: moment(),
        packageId: "",
        dealerMobileNumber: "",
        dealerEmail: "",
        direct_seller: false,
        displayMobile: 0,
        enableInquiry: 0,
        imageUrls: [],

        selected_ads_id: "",
        product_price: 0,
        brand: null,
        product_name: "",
        condition: null,
        reserved_status: "Available",
        type: null,
        color: "",
        frame_size: null,
        frame: "",
        wheel_size: 0,
        wheel_set: "",
        technology: "",
        fork: "",
        head_set: "",
        handle_stem: "",
        handlebar: "",
        front_derailleur: "",
        rear_derailleur: "",
        shifter: "",
        bottom_bracket: "",
        crank_set: "",
        cassette: "",
        chain: "",
        pedal: "",
        brakes: "",
        brake_lever: "",
        brake_rotor: "",
        front_hub: "",
        rear_hub: "",
        tires: "",
        saddle: "",
        seat_post: "",
        seat_clamp: "",
        speed: "",
        gears: "",
        weight: 0,

        youtube_video: "",

        description: "",
        accessories: "",
        notes: "",

        product_image_id: "",

        isVerified: false,
        isSendEmail: false,
    });

    const [isLoading, setIsLoading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedFile1, setSelectedFile1] = useState({ file: null, url: "" });
    const [selectedFile2, setSelectedFile2] = useState({ file: null, url: "" });
    
    // useEffect(() => {
    //   if (form) {
    //     const value = validateNewProductForm(form);
    //     if (value) {
    //       setEnableTab(1);
    //     } else {
    //       setEnableTab(0);
    //     }
    //   }
    // }, [form]);

    // useEffect(() => {
    //   if ((selectedFiles || form.imageUrls) && enableTab === 1) {
    //     const value = validateUpload(selectedFiles, form.imageUrls);
    //     if (value) {
    //       setEnableTab(2);
    //     } else {
    //       setEnableTab(1);
    //     }
    //   }
    // }, [selectedFiles, form.imageUrls, enableTab]);

    useEffect(() => {
        getPackages();
        getDealers();

        if (location.pathname.includes("/edit")) {
            setIsEdit(true);
        } else {
            orderImages();
        }

    }, []);

    useEffect(() => {
        if (isEdit) {
            getBicycleDetail();
        }
    }, [isEdit]);


    // console.log(dealers)

    useEffect(() => {
        if (form.dealerId) {
            dealers.data.forEach((dealer) => {
                if (dealer._id === form.dealerId) {
                    setForm({
                        ...form,
                        dealerMobileNumber: dealer.user_contact_numbers
                            ?.filter((el) => el.is_active === true)
                            .map((el) => el.number),
                        dealerEmail: dealer.user_contact_emails
                            ?.filter((el) => el.is_active === true)
                            .map((el) => el.email),
                        role: dealer.user_role,
                    });
                }
            });
        }
    }, [form.dealerId]);

    const getBicycleDetail = async () => {
        setIsLoading(true);
        const onGetBicycleDetail = fetchBicycleDetails(params.get("id"));
        onGetBicycleDetail
            .then((res) => {
                if (res.data.success) {
                    const parsedData = res.data.data;

                    const advertisementContactDetails = parsedData.advertisement_contact_details;
                    setForm(previous => {
                        return {
                            ...previous,
                            id: parsedData._id,
                            dealerId: advertisementContactDetails?._id ?? "",
                            role:
                                advertisementContactDetails?.role_name ?? "Dealer",
                            dealerEmail:
                                advertisementContactDetails?.user_contact_emails
                                    ?.email,
                            dealerMobileNumber:
                                advertisementContactDetails?.user_contact_numbers
                                    ?.number,
                            fullname: advertisementContactDetails?.name,
                            direct_seller: advertisementContactDetails?.direct_seller,

                            selected_ads_id: parsedData.selected_ads_id,
                            product_price: parsedData.product_price,
                            brand: parsedData.brand,
                            product_name: parsedData.product_name,
                            condition: parsedData.condition,
                            reserved_status: parsedData.reserved_status,
                            type: parsedData.type,
                            color: parsedData.color,
                            frame_size: parsedData.frame_size,
                            frame: parsedData.frame,
                            wheel_size: parsedData.wheel_size,
                            wheel_set: parsedData.wheel_set,
                            technology: parsedData.technology,
                            fork: parsedData.fork,
                            head_set: parsedData.head_set,
                            handle_stem: parsedData.handle_stem,
                            handlebar: parsedData.handlebar,
                            front_derailleur: parsedData.front_derailleur,
                            rear_derailleur: parsedData.rear_derailleur,
                            shifter: parsedData.shifter,
                            bottom_bracket: parsedData.bottom_bracket,
                            crank_set: parsedData.crank_set,
                            cassette: parsedData.cassette,
                            chain: parsedData.chain,
                            pedal: parsedData.pedal,
                            brakes: parsedData.brakes,
                            brake_lever: parsedData.brake_lever,
                            brake_rotor: parsedData.brake_rotor,
                            front_hub: parsedData.front_hub,
                            rear_hub: parsedData.rear_hub,
                            tires: parsedData.tires,
                            saddle: parsedData.saddle,
                            seat_post: parsedData.seat_post,
                            seat_clamp: parsedData.seat_clamp,
                            speed: parsedData.speed,
                            gears: parsedData.gears,
                            weight: parsedData.weight,
                            youtube_video: parsedData.youtube_video,
                            description: parsedData.description,
                            accessories: parsedData.accessories,
                            notes: parsedData.notes,

                            longAdImage1: parsedData.long_ad_image_1 ? parsedData?.long_ad_image_1 : '',
                            imageUrls: parsedData.product_image_urls,

                            // product_image_id: parsedData.product_image_id,
                            // isVerified: parsedData.is_verified,
                            // isSendEmail: parsedData.is_send_email ?? false,
                        }
                    })
                    orderImages(parsedData.product_image_urls)
                    setIsLoading(false);
                }
            })
            .catch((e) => console.log(e));
    };

    const orderImages = (product_image_urls = []) => {
        let image_id_urls = [];
        if(product_image_urls.length > 0) {
            for (let i = 0; i < noOfImages; i++) {
                if (product_image_urls?.[i]) {
                    image_id_urls.push({ imageIndex: `img${i + 1}`, orderNo: i + 1, _id: product_image_urls?.[i]?._id.$oid, url: product_image_urls?.[i]?.metadata?.image_url })
                } else {
                    image_id_urls.push({ imageIndex: `img${i + 1}`, orderNo: i + 1, _id: "", url: "" });
                }
            }
        } else {
            for (let i = 0; i < noOfImages; i++) {
                image_id_urls.push({ imageIndex: `img${i + 1}`, orderNo: i + 1, _id: "", url: "" });
            }
        }
        
        setSelectedFiles(image_id_urls);
    }



    const getDealers = () => {
        setDealers({ ...dealers, loading: true });
        const pagination = {
            page: 1,
            limit: 100000,
            keyword: "",
            // roleIds: roles.filter((el)=>roleNames.includes(el.name)).map((el)=>el._id),
            roleIds: ["619e5d55e7040000a2007b08", "619e5d67e7040000a2007b09"],
            isVerified: true,
        };

        const users = fetchUsers(pagination);
        users
            .then((res) => {
                if (res.data.success) {
                    setDealers({ ...dealers, loading: false, data: res.data.data });
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const getPackages = () => {
        const packages = fetchPackages();
        packages
            .then((res) => {
                if (res.data.success) {
                    setPackages(res.data.data);
                }
            })
            .catch((e) => console.log(e));
    };

    const validateBicycleForm = (data) => {
        if(
            !data.dealerId ||
            !data.product_price ||
            !data.product_name ||
            !data.condition
        ) {
            return false;
        }

        return true;
    };

    const checkAtLeastOneImageUpload = () => {
        return selectedFiles.some(obj => obj.url !== "");
    }

    return (
        <div className="form-table-container">
            <div className={`table-title-icon ${!isEdit ? "add" : "edit"}`}>
                <IoMdAddCircleOutline />
            </div>
            <p className={`table-title-text ${!isEdit ? "add" : "edit"}`}>
                {!isEdit ? "Add Bicycle" : `Edit Bicycle`}
            </p>

            <div className="form-body-container">
                <Tabs size="large">
                    <TabPane tab="Bicycle Details Form" key="1">
                        <BicycleDetails
                            form={form}
                            setForm={setForm}
                            dealers={dealers}
                            isEdit={isEdit}
                            selectedFile1={selectedFile1}
                            setSelectedFile1={setSelectedFile1}
                            selectedFile2={selectedFile2}
                            setSelectedFile2={setSelectedFile2}
                        />
                    </TabPane>
                    <TabPane tab="Upload Images" key="2" disabled={!validateBicycleForm(form)}>
                        <UploadBicycleImages
                            isEdit={isEdit}
                            form={form}
                            setForm={setForm}
                            selectedFiles={selectedFiles}
                            setSelectedFiles={setSelectedFiles}
                            noOfImages={noOfImages}
                        />
                    </TabPane>
                    <TabPane tab="Bicycle Ad Preview" key="3" disabled={!validateBicycleForm(form) || !checkAtLeastOneImageUpload()}>
                        <PreviewBicycle
                            isEdit={isEdit}
                            form={form}
                            selectedFiles={selectedFiles}
                            selectedFile1={selectedFile1}
                            selectedFile2={selectedFile2}
                        />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
};

export default BicycleFormIndex;
